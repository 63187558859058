// media queries
$maxXXL: 1399.9;
$maxLG: 991.9;

// colors
$white: #FFFFFF;
$greyBorder: #ced4da;
$grey: grey;
$transparent: rgba(0, 0, 0, 0);
$primaryBootstrap: #007BFF;
