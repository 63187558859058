@import "./variables";

html {
  height: 100%;
  overflow-x: hidden; }

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

#root {
  height: 100%; }

.button {
  text-transform: uppercase; }

.relative {
  position: relative; }

.subtitle {
  font-size: 12px; }

.full-height {
  height: 100%; }

.loader-wrapper {
  text-align: center; }

#visitors-modal-title {
  text-transform: uppercase;
  font-size: 16px; }

#controlled-tabs-tab-total, #controlled-tabs-tab-module, #controlled-tabs-tab-sessions {
  border: 1px solid $greyBorder;
  border-radius: 0;
  color: $grey;
  font-weight: 500;
  &.active {
    color: #104AA4; } }
#controlled-tabs-tab-total {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem; }
#controlled-tabs-tab-sessions {
  border-bottom-right-radius: .25rem;
  border-top-right-radius: .25rem; }

// react-dates:
.DateRangePickerInput > .DateInput {
  margin-right: 25px; }
.DateRangePickerInput > .DateInput ~ .DateInput {
  margin-right: 0; }
.DateRangePickerInput input.DateInput_input__focused {
  border-bottom: 2px solid $primaryBootstrap; }

// react-bootstrap-typeahead:
.rbt-input-multi.form-control {
  height: 38px;
  overflow-y: auto;
  & input::placeholder {
    font-weight: 500; } }
.rbt-token .rbt-token-remove-button {
  border: 0 solid $transparent;
  background-color: $primaryBootstrap;
  color: $white;
  left: 0;
  border-radius: .25rem;
  padding-top: 0;
  padding-bottom: 0;
  top: 0;
  cursor: pointer;
  & span:first-of-type {
    vertical-align: text-top; } }
.rbt-token.rbt-token-removeable {
  padding-left: 26px;
  background-color: $primaryBootstrap;
  color: $white;
  cursor: default; }

@media print {
  html, body {
    float: none !important; }
  div {
    float: none !important;
    position: relative !important;
    display: block;
    box-sizing: content-box !important; }
  .page-break-inside {
    page-break-inside: avoid;
    display: inline-block;
    ::after {
      display: block;
      height: 0;
      visibility: hidden;
      content: ''; } }
  .button {
    font-size: 12px; }
  .container {
    max-width: 100%;
    width: 100%; }
  body {
    width: 100%;
    height: 100%;
    overflow: visible; } }
