.body {
  &__core {
    display: flex;
    width: 100%; }
  &__header {
    padding-left: 15%;
    text-transform: uppercase;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: bolder;
    align-items: center;
    & svg {
      height: 16px;
      cursor: pointer; }
    & p {
      margin-bottom: 0; } }
  &__arrow {
    height: 16px;
    width: 16px; }
  &__xy {
    width: 50%;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bolder;
    padding-right: 5px; }
  &__x {
    width: 50%;
    padding-right: 5px;
    line-height: 19px;
    font-size: 0.85rem;
    display: flex;
    align-items: center; }
  &__y {
    width: 85%;
    font-size: 16px;
    font-weight: bolder;
    display: flex;
    justify-content: space-between;
    text-align: center; }
  &__col {
    width: 100%; }
  &__table {
    width: 85%;
    display: flex;
    justify-content: space-between;
    min-width: 452px;
    overflow-x: hidden; } }
