.hcharts {
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between; }
    &__labels {
        display: flex;
        align-items: center;
        justify-content: flex-end; }
    &__label-text {
        font-size: 12px;
        &:first-of-type {
            margin-right: 16px; } }
    &__label {
        width: 12px;
        height: 12px;
        margin-right: 4px; } }
