@import "../../../styles/variables";

.chart-label {
  text-transform: uppercase;
  display: inline;
  background-color: $white;
  top: -10px;
  margin-bottom: 0;
  position: absolute;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
  font-weight: bolder;
  letter-spacing: 1px;
  &__right {
    right: 8px; }
  &__blue {
    color: rgb(165,177,189); }
  &__green {
    color: rgb(171,192,182); }
  &__darkblue {
    color: rgb(180,177,198); } }
