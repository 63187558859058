@import "../../styles/mixins";
@import "../../styles/variables";

.tabs-bar {
  margin-bottom: 38px;
  &__item {
    cursor: pointer; } }

.add-btn {
  min-width: 194px; }

.print {
  &__icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-right: 10px; } }

.btn-text {
  display: inline-block;
  text-transform: uppercase; }

#print__btn {
  display: inline-flex;
  align-items: center;
  min-width: 186px; }

@include r($maxLG) {
  #buttons-wrapper {
    flex-direction: column;
    align-items: flex-end; } }

@media print {
  .add-btn {
    min-width: 0; }
  #print__btn {
    min-width: 0;
    display: none; }
  .tabs-bar {
    max-width: 95%;
    &__nav {
      min-width: 378px; } } }

