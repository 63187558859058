@import "../../styles/mixins";
@import "../../styles/variables";

.bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  @include r($maxXXL) {
    justify-content: center; }
  &__dates {
    margin-right: 25px;
    position: relative;
    & input {
      border-radius: .25rem;
      border: 1px solid $greyBorder;
      padding-top: 7px;
      padding-bottom: 5px;
      font-size: 1rem;
      color: $grey;
      font-weight: 500; } }
  &__modes {
    position: relative;
    margin-right: 25px; }
  &__modes-container {
    display: flex;
    align-items: center;
    padding-top: 3px;
    & button:first-child {
      border-bottom-left-radius: .25rem;
      border-top-left-radius: .25rem; }
    & button:last-child {
      border-bottom-right-radius: .25rem;
      border-top-right-radius: .25rem; } }
  &__mode {
    height: 32px;
    vertical-align: middle;
    padding-right: 10px;
    padding-left: 10px;
    line-height: 30px;
    background-color: $transparent;
    font-size: .9rem;
    color: $grey;
    font-weight: 500;
    text-transform: uppercase; }
  &__mode-active {
    height: 32px;
    vertical-align: middle;
    padding-right: 10px;
    padding-left: 10px;
    line-height: 30px;
    background-color: #F5F5FA;
    font-size: .9rem;
    color: $grey;
    font-weight: 500;
    text-transform: uppercase; }
  &__typehead {
    width: 37%;
    position: relative;
    margin-right: 25px;
    @include r($maxXXL) {
      width: 85%; }
    @include r($maxLG) {
      width: 80%; } } }

@media print {
  .bar {
    justify-content: center;
    &__typehead {
      width: 70%; } } }
