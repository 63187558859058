.wrapper {
  height: 100%;
  align-items: center;
  justify-content: center; }

.form {
  width: 100%; }

.group {
  min-height: 95px;
  &:last-of-type {
    margin-bottom: 24px; } }

.button {
  min-width: 30%; }
